import React from 'react';

import type { Meeting } from 'models';

import { __ } from 'helpers/i18n';

import { Button, Icon } from 'components';

import ScheduleMeetingModal from 'scenes/components/userReview/ScheduleMeetingModal';

type Props = {
  userReviewId: string;
  meeting: Meeting;
  disabled: boolean;
};

const CalendarButton = ({ meeting, userReviewId, disabled }: Props) => {
  const [modalIsActive, setModalActive] = React.useState(false);

  return (
    <>
      <Button
        hasIconOnly
        color="secondary"
        disabled={disabled}
        disabledExplanation={__(
          "You don't have the permission to edit this meeting"
        )}
        className="gtm-calendar-square-button"
        onClick={() => setModalActive(true)}
      >
        <Icon
          name={meeting.isScheduled ? 'event_available' : 'calendar_today'}
        />
      </Button>

      <ScheduleMeetingModal
        isActive={modalIsActive}
        onClose={() => setModalActive(false)}
        meeting={meeting}
        userReviewId={userReviewId}
      />
    </>
  );
};

export default CalendarButton;
